<template>
  <div>
    <template v-if="loading">
      <b-spinner
        v-for="i in 3"
        :key="i"
        class="mr-1"
        small
        label="Small Spinner"
        type="grow"
      ></b-spinner>
    </template>

    <b-card-text class="incre-decre-sec" v-if="!loading">
      <template v-if="qtyFinder.qty == undefined || qtyFinder.qty == 0">
        <button @click="increase" class="product-add">
          <b-icon icon="basket2-fill"></b-icon> Add
        </button>
      </template>
      <template v-if="qtyFinder.qty > 0">
        <b-icon @click="decrease" icon="dash" class="plus-icon"></b-icon>
        <input
          disabled="true"
          v-model="qtyFinder.qty"
          class="qty-input"
          type="text"
        />
        <b-icon @click="increase" icon="plus" class="plus-icon"></b-icon>
        <div @click="$router.push('/checkout/')" v-if="gotoCartVisibleKey">
          <button
            v-if="$route.path !== `/checkout/` && show"
            class="product-add ml-2"
          >
            <b-icon icon="basket2-fill"></b-icon> Go To Cart
          </button>
        </div>
      </template>
    </b-card-text>
    <template>
      <button
        v-if="$route.path == `/checkout/`"
        @click="removeItemFromCart"
        class="remove"
      >
        Remove
      </button>
    </template>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "increment-decrement",
  data() {
    return {
      loading: false,
      show: true,
    };
  },
  computed: {
    ...mapState({
      cartData: (state) => state.checkout.cartData,
    }),
    qtyFinder() {
      let qtyFinder = {};
      try {
        let res = this.cartData.filter((el) => el._id == this.cartItem._id);
        if (Array.isArray(res) && res.length > 0) {
          if (res[0].qty > 0) {
            qtyFinder = res[0];
          } else {
            qtyFinder.qty = 0;
          }
        } else {
          qtyFinder = {};
        }
      } catch {
        qtyFinder = {};
      }
      return qtyFinder;
    },
  },
  props: {
    cartItem: {
      default: () => {},
      type: Object,
    },
    callCheckout: {
      type: Boolean,
      default: false,
    },
    gotoCartVisibleKey: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    async increase() {
      this.loading = true;
      this.show = true;
      try {
        if (this.cartData.length > 0) {
          if (this.cartData[0].provider == this.cartItem.provider) {
            await this.$store.dispatch("checkout/increaseCartQty", {
              cartItem: this.cartItem,
              callApi: this.callCheckout,
            });
            this.loading = false;
            if (this.callCheckout)
              await this.$store.dispatch("checkout/checkoutFinal");
          } else {
            this.$swal({
              title: `Sure??`,
              text: "You have product from different seller in your cart.Do you want to remove it and add this item?",
              type: "warning",
              showCancelButton: true,
              confirmButtonColor: "#3085d6",
              confirmButtonText: "Yes",
            }).then(async (result) => {
              if (result.value) {
                await this.$store.dispatch("checkout/clearCart");
                await this.$store.dispatch("checkout/increaseCartQty", {
                  cartItem: this.cartItem,
                  callApi: this.callCheckout,
                });
              }
              this.loading = false;
            });
          }
        } else {
          await this.$store.dispatch("checkout/increaseCartQty", {
            cartItem: this.cartItem,
            callApi: this.callCheckout,
          });
          this.loading = false;
          if (this.callCheckout)
            await this.$store.dispatch("checkout/checkoutFinal");
        }
      } catch {
        this.loading = false;
      }
    },
    async decrease() {
      this.loading = true;
      try {
        await this.$store.dispatch("checkout/decreaseCartQty", {
          cartItem: this.cartItem,
          callApi: this.callCheckout,
        });
        this.loading = false;
        if (this.callCheckout)
          await this.$store.dispatch("checkout/checkoutFinal");
      } catch {
        this.loading = false;
      }
    },

    async removeItemFromCart() {
      await this.$store.dispatch("checkout/removeItem", {
        cartItem: this.cartItem,
        callApi: this.callCheckout,
      });
      if (this.callCheckout)
        await this.$store.dispatch("checkout/checkoutFinal");
    },
  },
};
</script>
<style scoped>
.icon-height {
  height: 19px !important;
}
.remove {
  font-size: 14px;
  color: #fe7b08;
  font-size: 16px;
  margin-left: 10px;
}

.product-add {
  width: 100%;
  font-size: 15px;
  padding: 6px 0px;
  border-radius: 6px;
  text-align: center;
  text-transform: capitalize;
  color: #fe7b08;
  font-weight: 600;
  background: transparent;
  border: 1px solid #fe7b08;
  border-radius: 4px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  transition: all linear 0.3s;
  -webkit-transition: all linear 0.3s;
  -moz-transition: all linear 0.3s;
  -ms-transition: all linear 0.3s;
  -o-transition: all linear 0.3s;
}
.product-add svg {
  font-size: 14px;
  margin-right: 5px;
}
.incre-decre-sec .plus-icon.b-icon.bi {
  width: 25px;
  height: 25px;
  line-height: 25px;
  border: 1px solid #585858;
  border-radius: 50px;
  cursor: pointer;
}
.incre-decre-sec {
  align-items: center;
  justify-content: center;
  display: flex;
}
.incre-decre-sec .qty-input {
  width: 25px;
  height: 25px;
  line-height: 25px;
  text-align: center;
  border: 1px solid #ddd;
  margin: 0px 5px;
}
</style>
